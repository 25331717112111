<template lang="pug">
  article#project.project.fixed.z-40.overlay.overflow-y-scroll.scrollbars-hidden
    //- body
    .min-h-screen.bg-black(ref="body")
      transition(name="fade")
        project-body(v-if="mounted && doc", :doc="doc")

    //- empty area, triggers auto close
    //- ios: ht should be taller than window
    observer#scroll-end.h-screen.mt-3(:threshold="0.35", @visible="autoClose = true", @hidden="autoClose = false", :style="{height: 1.2 * winH + 'px'}")
</template>

<script>
import { mapState } from "vuex";
import ProjectBody from "@/components/ProjectBody";
import Observer from "@/components/IntersectionObserver";
import throttle from "lodash/throttle";
export default {
  name: "Project",
  components: { ProjectBody, Observer },
  computed: {
    ...mapState(["winH"]),
    doc() {
      return this.$store.state.projects.find(
        prj => prj.uid === this.$route.params.project
      );
    },
    bg_color() {
      return this.doc.data.bg_color;
    },
    text_color() {
      return this.doc.data.text_color;
    }
  },
  data() {
    return {
      autoClose: false,
      autoCloseScroll: null,
      afterScroll: null,
      afterScroll_title: null,
      mounted: false
    };
  },
  methods: {
    closeProject() {
      // go to parent route
      clearTimeout(this.afterScroll_title);
      const path = this.$route.path.split("/");
      const parentPath = path.slice(0, path.length - 1).join("/");
      // preserve hash so #info overlay doesn't close if is open
      const hash = this.$route.hash ? this.$route.hash : "";
      // go
      if (this.$route.hash === "#info")
        this.$root.$emit(
          "bindBlurLayerToOverlayScroll",
          document.getElementById("info")
        );
      return this.$router.push(parentPath + hash);
    },
    onScroll: throttle(function() {
      // (auto) close
      clearTimeout(this.afterScroll);
      clearTimeout(this.afterScroll_title);

      const scroll = this.$el.scrollTop;
      const scrollDir = scroll > this.lastScroll ? "down" : "up";
      this.lastScroll = scroll;

      if (scrollDir === "up") {
        this.$store.commit("TITLE_INACTIVE");
        if (!this.$store.state.projectMenuActive)
          this.$store.commit("PROJECT_MENU_ACTIVE");
      } else {
        this.$store.commit("TITLE_ACTIVE");
        this.afterScroll_title = setTimeout(() => {
          this.$store.commit("TITLE_INACTIVE");
          this.$store.commit("PROJECT_MENU_ACTIVE");
        }, 501);
      }

      const bodyHt = this.$refs.body.offsetHeight;
      if (!bodyHt) return;

      const hitBottom = this.$el.scrollTop >= bodyHt - 2;

      if (hitBottom) {
        // close
        this.closeProject();
        this.$el.removeEventListener("scroll", this.onScroll);
      } else if (this.autoClose) {
        // auto-close after scroll
        this.afterScroll = setTimeout(() => {
          this.scrollClose(400);
        }, 101);
      }
    }, 50),
    scrollClose(speed = 800) {
      if (this.autoCloseScroll) return;
      this.autoCloseScroll = this.$scrollTo("#scroll-end", {
        duration: speed,
        container: "#project",
        cancelable: true,
        onDone: () => {
          this.autoCloseScroll = null;
        }
      });
    }
  },
  mounted() {
    this.$el.addEventListener("scroll", this.onScroll);
    this.$nextTick(() => {
      this.mounted = true;
    });
    this.$root.$emit("bindBlurLayerToOverlayScroll", this.$el);
  }
};
</script>

<style></style>
